

































































































































































































































































































import { debounceProcess } from "@/helpers/debounce";
import { useInternalContract } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { DataListMasterAsset } from "@/models/interface/assets.interface";
import {
  ContactData,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { InternalContractCloseRequestDTO } from "@/models/interface/internal-contract/InternalContractClose.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { DataListInternalContract } from "@/models/interface/salesOrder.interface";
import { assetsServices } from "@/services/assets.service";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import { salesOrderServices } from "@/services/salesorder.service";
import moment from "moment";
import printJs from "print-js";
import Vue from "vue";

export interface DataListRevised {
  value: string;
  label: string;
}

type Row = Omit<DataListInternalContract, "key" | "no"> & {
  key: string;
  no: number;
};

export default Vue.extend({
  name: "ListInternalContract",
  mixins: [MNotificationVue],
  data() {
    this.getListCustomer = debounceProcess(this.getListCustomer, 200);
    this.getListInternalContractNo = debounceProcess(
      this.getListInternalContractNo,
      200
    );
    this.getListBranch = debounceProcess(this.getListBranch, 200);
    return {
      selectedRowKeys: [] as Array<string>,
      modelForm: {
        date: [] as any,
      },
      dataSource: [] as Array<Row>,
      columnsTable: [
        {
          title: this.$t("lbl_no"),
          key: "no",
          dataIndex: "no",
          width: 100,
        },
        {
          title: this.$t("lbl_internal_contract_no"),
          dataIndex: "documentNo",
          key: "documentNo",
          width: 200,
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_contract_date"),
          dataIndex: "contractDate",
          key: "contractDate",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_branch"),
          dataIndex: "branchName",
          key: "branchName",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_pic"),
          dataIndex: "picName",
          key: "picName",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_customer_name"),
          dataIndex: "customerName",
          key: "customerName",
          width: 350,
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_currency"),
          dataIndex: "currency",
          key: "currency",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_rent_period"),
          dataIndex: "rentPeriod",
          key: "rentPeriod",
          width: 200,
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_total_rent"),
          dataIndex: "totalRentPrice",
          key: "totalRentPrice",
          width: 200,
          scopedSlots: { customRender: "currency" },
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "status",
          key: "status",
          scopedSlots: { customRender: "nullable" },
        },
        {
          dataIndex: "operation",
          align: "center",
          scopedSlots: { customRender: "operation" },
        },
      ],
      params: {} as RequestQueryParamsModel,
      page: 1 as number,
      totalElements: 0 as number,
      limit: 10 as number,
      loading: {
        close: false,
        listCustomer: false,
        internalContractNo: false,
        branchName: false,
        status: false,
        revised: false,
        table: false,
        submit: false,
        download: false,
        print: false,
        unitCode: false,
      },
      DEFAULT_DATE_FORMAT,
      attachmentFile: "" as string,
      dataListCustomer: [] as ContactData[],
      dataListInternalContractNo: [] as DataListInternalContract[],
      dataListBranch: [] as DataWarehouseBranch[],
      dataListStatus: [] as ResponseListMaster[],
      dataUnitCode: [] as DataListMasterAsset[],
      dataListRevised: [
        {
          value: "yes",
          label: "Yes",
        },
        {
          value: "no",
          label: "No",
        },
      ] as DataListRevised[],
      form: this.$form.createForm(this, { name: "listInternalContract" }),
      formRules: {
        customerName: {
          label: "lbl_customer_name",
          name: "customerName",
          placeholder: "lbl_customer_name_placeholder",
          decorator: [
            "customerName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        internalContractNo: {
          label: "lbl_internal_contract_no",
          name: "internalContractNo",
          placeholder: "lbl_internal_contract_no",
          decorator: [
            "internalContractNo",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        internalContractDate: {
          label: "lbl_internal_contract_date",
          name: "internalContractDate",
          placeholder: "lbl_internal_contract_date",
          decorator: [
            "internalContractDate",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        unitCode: {
          label: "lbl_unit_code",
          name: "unitCode",
          placeholder: "lbl_unit_code_placeholder",
          decorator: [
            "unitCode",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        branchName: {
          label: "lbl_branch",
          name: "branchName",
          placeholder: "lbl_branch",
          decorator: [
            "branchName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        status: {
          label: "lbl_status",
          name: "status",
          placeholder: "lbl_status",
          decorator: [
            "status",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        revised: {
          label: "lbl_revised",
          name: "revised",
          placeholder: "lbl_revised",
          decorator: [
            "revised",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
    };
  },
  created() {
    this.getListCustomer("");
    this.getListInternalContractNo("");
    this.getListBranch("");
    this.getListStatus();
    this.getUnitCode("");
  },
  methods: {
    moment,
    getUnitCode(valueSearch: string) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      };
      if (valueSearch) params.search = `unitCode~*${valueSearch}*`;
      this.loading.unitCode = true;
      assetsServices
        .listMasterAsset(params)
        .then(response => {
          this.dataUnitCode = response.data;
        })
        .finally(() => (this.loading.unitCode = false));
    },
    getListCustomer(valueSearch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
        search: "customer~true_AND_active~true",
        sorts: "firstName:asc",
      };
      if (valueSearch)
        params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`;
      this.loading.listCustomer = true;
      contactServices
        .listContactData(params)
        .then(response => (this.dataListCustomer = response.data))
        .finally(() => (this.loading.listCustomer = false));
    },
    getListInternalContractNo(valueSearch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      };
      if (valueSearch) params.search = `documentNo~*${valueSearch}*`;
      this.loading.internalContractNo = true;
      salesOrderServices
        .getListInternalContract(params)
        .then(response => (this.dataListInternalContractNo = response.data))
        .finally(() => (this.loading.internalContractNo = false));
    },
    getListBranch(valueSearch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      };
      if (valueSearch)
        params.search = `name~*${valueSearch}*_OR_address~*${valueSearch}*`;
      this.loading.branchName = true;
      logisticServices
        .listWarehouseBranch(params, "")
        .then(response => (this.dataListBranch = response.data))
        .finally(() => (this.loading.branchName = false));
    },
    getListStatus() {
      const params: RequestQueryParamsModel = {
        name: "STATUS_INTERNAL_CONTRACT",
      };
      this.loading.status = true;
      masterServices
        .listMaster(params)
        .then(response => {
          this.dataListStatus = response.filter(
            dataFilter =>
              dataFilter.value !== "New" && dataFilter.value !== "Revised"
          );
        })
        .finally(() => (this.loading.status = false));
    },
    handleDownload() {
      this.form.validateFields((err, res) => {
        if (err) return;
        this.params.params = this.checkParams(res);
        this.loading.download = true;
        salesOrderServices
          .getReportDownloadInternalContract(this.params)
          .then(response => {
            if (response) {
              const url = window.URL.createObjectURL(new Blob([response]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "internal_contract.xlsx"); //or any other extension
              document.body.appendChild(link);
              link.click();
            }
          })
          .finally(() => (this.loading.download = false));
      });
    },
    handlePrint() {
      this.form.validateFields((err, res) => {
        if (err) return;
        this.params.params = this.checkParams(res);
        this.loading.print = true;
        salesOrderServices
          .getReportPrintInternalContract(this.params)
          .then(response => {
            if (response) {
              const url = window.URL.createObjectURL(new Blob([response]));
              printJs(url);
            }
          })
          .finally(() => (this.loading.print = false));
      });
    },
    responseEditTable(response) {
      this.$router.push(`/sales/internal-contract/view/${response.data.id}`);
    },
    responseViewTable({ id }) {
      this.$router.push(`/sales/internal-contract/view/${id}`);
    },
    onRangePickerChange(dates: Array<any>): void {
      if (!dates.length) {
        this.modelForm.date = undefined;
      }
    },
    handleCreate() {
      this.$router.push("/sales/internal-contract/create");
    },
    responsePageSizeChange(response: ResponsePagination) {
      this.limit = response.size;
      this.page = 1;
      this.onSubmit();
    },
    responseCurrentPageChange(response: ResponsePagination) {
      this.page = response.page;
      this.onSubmit();
    },
    handleCancel() {
      this.modelForm.date = [];
      this.dataSource = [];
      this.totalElements = 0;
      this.form.resetFields();
    },
    disabledDate(current) {
      return current > moment().endOf("day");
    },
    handleSelectType(value: string): void {
      this.form.setFieldsValue({
        adj: value,
      });
    },
    checkValue(value): string {
      if (value && !value.includes("undefined")) {
        return value;
      } else {
        return ",ALL";
      }
    },
    checkParams(res): string {
      let params = "";
      // company is mandatory
      params += this.$store.state.authStore.authData.companyName;
      params += this.checkValue(
        `,${this.dataListCustomer
          .find(dataFind => dataFind.id === res["customerName"])
          ?.fullName?.replaceAll(",", " ")}`
      );
      params += this.checkValue(`,${res["internalContractNo"]}`);
      // date from & date to
      params += this.checkValue(
        res["internalContractDate"]
          ? `,${moment(res["internalContractDate"][0]).format(
              DEFAULT_DATE_FORMAT
            )}`
          : null
      );
      params += this.checkValue(
        res["internalContractDate"]
          ? `,${moment(res["internalContractDate"][1]).format(
              DEFAULT_DATE_FORMAT
            )}`
          : null
      );
      params += this.checkValue(
        `,${
          this.dataListBranch.find(
            dataFind => dataFind.id === res["branchName"]
          )?.name
        }`
      );
      params += this.checkValue(`,${res["status"]}`);
      params += this.checkValue(`,${res["revised"]}`);
      params += this.checkValue(`,${res["unitCode"]}`);

      return params;
    },
    assignSearch(key, value, and): string {
      if (key === "customerName" && value)
        return and + `customer.secureId~${value}`;
      else if (key === "internalContractNo" && value)
        return and + `documentNo~${value}`;
      else if (key === "internalContractDate" && value)
        return (
          and +
          `contractDate>=${moment(value[0])
            .set({ hour: 0, minute: 0, second: 0 })
            .utcOffset("+07")
            .format()}_AND_contractDate<=${moment(value[1])
            .set({ hour: 23, minute: 59, second: 59 })
            .utcOffset("+07")
            .format()}`
        );
      else if (key === "branchName" && value)
        return and + `branch.secureId~${value}`;
      else if (key === "status" && value)
        return and + `status~${value.replace(" ", "_")}`;
      else if (key === "revised" && value === "yes") return and + `revised>0`;
      else if (key === "revised" && value === "no") return and + `revised~0`;
      else return "";
    },
    dynamicSearch(res): string {
      let search = "";
      Object.keys(res).forEach(key => {
        if (!search) {
          search = this.assignSearch(key, res[key], "");
        } else {
          search += this.assignSearch(key, res[key], "_AND_");
        }
      });
      return search;
    },
    onSubmit(resetPage = false): void {
      this.form.validateFields((err, res) => {
        if (err) return;
        const params: RequestQueryParamsModel = {
          page: resetPage ? 0 : this.page - 1,
          limit: this.limit,
          sorts: "createdDate:DESC",
        };
        if (res.unitCode) params.unitId = res.unitCode;
        params.search = this.dynamicSearch(res);
        this.loading.table = true;
        salesOrderServices
          .getListInternalContract(params)
          .then(data => {
            this.dataSource = data.data.map<Row>((dataMap, index) => ({
              ...dataMap,
              no: (params.page as number) * this.limit + index + 1,
              key: dataMap.id,
            }));
            this.params = params;
            this.params.params = this.checkParams(res);
            this.totalElements = data.totalElements;
          })
          .finally(() => (this.loading.table = false));
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    onChangeTable(pagination: {
      total: number;
      current: number;
      pageSize: number;
    }): void {
      this.page = pagination.current;
      if (this.limit !== pagination.pageSize) {
        this.page = 1;
      }

      this.limit = pagination.pageSize;
      this.onSubmit();
    },
    onSelectChange(selectedRowKeys: Array<string>): void {
      this.selectedRowKeys = selectedRowKeys;
    },
    closeIcs(): void {
      const { selectedRowKeys } = this;
      const { closeBulk } = useInternalContract();
      const body: InternalContractCloseRequestDTO = {
        internalContractIds: selectedRowKeys,
      };
      this.loading.close = true;
      closeBulk(body)
        .then(response => {
          this.showNotifSuccess("notif_success_close_ics_data", {
            data: response.closedInternalContractNumber.join(","),
          });
          this.page = 1;
          this.onSubmit();
          this.selectedRowKeys = [];
        })
        .finally(() => {
          this.loading.close = false;
        });
    },
  },
});
